import React, { useState } from 'react'
import ReactMarkdown from 'react-markdown'
import { graphql } from 'gatsby'

import Layout from '~/components/layout'
import SEO from '~/components/seo'
import PaymentForm from '~/components/paymentForm'

const PaymentPage = ({ data: { allStrapiPaiement } }) => {
  const { title, title_caution, graph, graph_caution, background } =
    allStrapiPaiement.nodes[0]
  const seo = {
    title: 'Paiement',
    metaDescription:
      'Payez vos factures suite à vos achats en salle des ventes. Une transaction Banque Populaire rapide et sécurisée.',
  }

  const [option, setOption] = useState('invoice')

  return (
    <Layout isAbsolute={false}>
      <SEO seo={seo} />
      <div
        className="bg-no-repeat bg-cover"
        style={{
          backgroundImage: `url(${background.url})`,
        }}
      >
        <div className="grid grid-cols-12 container">
          <div className="col-start-1 col-end-13 min-h-screen laptop:col-start-4 laptop:col-end-10">
            <div className="mt-5 mb-8 pt-6 pb-2 bg-gray-100 push-content">
              <div className="px-3 text-center">
                <div className="largeMobile:flex largeMobile:w-max mx-auto mt-4 mb-6 p-1 bg-white">
                  <div
                    onClick={() => setOption('invoice')}
                    className={`${
                      option === 'invoice' ? 'bg-gray-100' : 'bg-white'
                    } p-2 cursor-pointer`}
                  >
                    <label
                      className={`${
                        option === 'invoice' ? 'font-bold' : ''
                      } uppercase cursor-pointer tracking-wider`}
                      htmlFor="all"
                    >
                      Paiement de facture
                    </label>
                    <input id="invoice" className="hidden" type="radio" />
                  </div>
                  <div
                    onClick={() => setOption('caution')}
                    className={`${
                      option === 'caution' ? 'bg-gray-100' : 'bg-white'
                    } p-2 cursor-pointer`}
                  >
                    <label
                      className={`${
                        option === 'caution' ? 'font-bold' : ''
                      } uppercase cursor-pointer tracking-wider`}
                      htmlFor="besancon"
                    >
                      Acompte
                    </label>
                    <input id="caution" className="hidden" type="radio" />
                  </div>
                </div>
                {option == 'invoice' && (
                  <>
                    <h3 className="mb-4 font-bold uppercase tracking-wide">
                      {title}
                    </h3>
                    <ReactMarkdown className="text-left max-w-500 mx-auto pb-5 mb-2 border-b border-gray-400 tablet:mb-0">
                      {graph}
                    </ReactMarkdown>
                  </>
                )}
                {option == 'caution' && (
                  <>
                    <h3 className="mb-4 font-bold uppercase tracking-wide">
                      {title_caution}
                    </h3>
                    <ReactMarkdown className="text-left max-w-500 mx-auto pb-5 mb-2 border-b border-gray-400 tablet:mb-0">
                      {graph_caution}
                    </ReactMarkdown>
                  </>
                )}
              </div>
              <div className="col-start-1 col-end-13">
                <PaymentForm option={option} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query paymentQuery {
    allStrapiPaiement {
      nodes {
        title
        title_caution
        graph
        graph_caution
        background {
          url
        }
      }
    }
  }
`

export default PaymentPage
