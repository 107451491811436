import React, { useState, useRef } from 'react'
import Input from '~/components/input'
import Button from '~/components/button'
import { InformationCircleIcon } from '@heroicons/react/24/outline'

const apiUrl = process.env.GATSBY_STRAPI_URL || 'http://localhost:1337'

const PaymentForm = ({ option }) => {
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState(null)

  const firstNameRef = useRef(null)
  const lastNameRef = useRef(null)
  const emailRef = useRef(null)
  const amountRef = useRef(null)
  const invoiceIdRef = useRef(null)

  const generateTransactionId = () =>
    Array.from({ length: 6 }, () =>
      Math.floor(Math.random() * 36).toString(36)
    ).join('')

  const removeAccent = string => {
    return string.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
  }

  const handleFormSubmit = async (e, isInvoice) => {
    e.preventDefault()
    setLoading(true)

    const paymentConf = {
      amount: amountRef.current?.value,
      firstName: firstNameRef.current?.value,
      lastName: lastNameRef.current?.value,
      email: emailRef.current?.value,
      invoiceId: isInvoice
        ? removeAccent(invoiceIdRef.current?.value)
        : undefined,
      transactionId: generateTransactionId(),
    }

    try {
      const response = await fetch(`${apiUrl}/paiement`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ paymentConf }),
      })

      if (!response.ok) {
        throw new Error('Network response was not ok.')
      }
      const formHtml = await response.text()
      const container = document.createElement('div')
      container.innerHTML = formHtml

      document.body.appendChild(container)

      const form = container.querySelector('form')
      if (form) {
        form.submit()
      } else {
        console.error('Form not found in the HTML response.')
      }
    } catch (error) {
      setMessage(error.message)
    } finally {
      setLoading(false)
    }
  }

  const isInvoice = option === 'invoice'

  return (
    <>
      <form
        onSubmit={e => handleFormSubmit(e, isInvoice)}
        className={`w-full mt-5 mb-4 px-3`}
      >
        <div className="text-center mt-2 mb-4 text-lg">
          Informations de l'acheteur
        </div>
        <Input
          name="first_name"
          isRequired
          placeholder="Prénom"
          ref={firstNameRef}
        />
        <Input
          name="last_name"
          isRequired
          placeholder="Nom"
          ref={lastNameRef}
        />
        <Input
          name="email"
          type="email"
          size="30"
          isRequired
          placeholder="Email"
          ref={emailRef}
        />
        {isInvoice && (
          <>
            <Input
              name="invoice_id"
              isRequired
              placeholder="Numéro de facture"
              ref={invoiceIdRef}
              hasIndication
            />
            <p className="mt-1 mb-3 text-tiny text-gray-800 flex items-start">
              <InformationCircleIcon
                className="w-3 mr-1 block relative flex-shrink-0"
                style={{ top: '1px' }}
              />
              <span>
                Numéro à précéder du mot "envoi" uniquement pour le paiement
                d'une expédition.
              </span>
            </p>
          </>
        )}
        {isInvoice && (
          <Input
            isRequired
            placeholder="Montant à régler (en €)"
            type="number"
            step=".01"
            min="1"
            max="15000"
            name="amount"
            ref={amountRef}
          />
        )}
        {!isInvoice && (
          <Input
            name="amount"
            isRequired
            placeholder="Montant à avancer (en €)"
            type="number"
            step=".01"
            min="1"
            max="1500"
            ref={amountRef}
          />
        )}
        {!isInvoice && (
          <div className="mt-4">
            <input
              className="cursor-pointer"
              id="approval"
              required
              type="checkbox"
            />
            <label className="mt-2 ml-1" htmlFor="approval">
              Je certifie avoir pris contact avec la Maison de Ventes avant
              d'envoyer ma caution.
            </label>
          </div>
        )}
        <div className="mt-3 mb-5">
          <input
            className="cursor-pointer"
            id="consent"
            required
            type="checkbox"
          />
          <label className="ml-1" htmlFor="consent">
            En soumettant ce formulaire, j'accepte que les informations saisies
            soient transmises et traitées par Banque Populaire pour le paiement
            de ma facture. Aucune donnée personnelle n’est conservée par le site
            actuel via ce formulaire.
          </label>
        </div>
        <Button
          label={loading ? 'Chargement...' : 'Accéder au paiement'}
          type="submit"
          name="payer"
          value="Payer"
        />
        {message && <div data-test="payment-message">{message}</div>}
      </form>
    </>
  )
}

export default PaymentForm
